import React from 'react';

export default function useDebounce(
  value: string,
  delay: number,
  onChangeValue?: (value: string) => void
) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      onChangeValue?.(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, onChangeValue]);
  return debouncedValue;
}
